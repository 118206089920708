import React from 'react';
import Img from 'gatsby-image';

import { Link } from 'components/common/link/Link';
import settingsHook from 'hooks/settings/settings.hook';

import { company, company__logo, company__details } from './Company.scss';

export default (): JSX.Element => {
  const settings = settingsHook();

  return (
    <div className={company}>
      <Link to="/" title={settings.brand_name}>
        <Img
          fluid={settings.logo.localFile.childImageSharp.fluid}
          className={company__logo}
          alt={`Logo for ${settings.brand_name}.`}
          fadeIn
        />
      </Link>

      <div className={company__details}>
        <span>
          {settings.brand_name},&nbsp;<span> registered in England & Wales at</span>
        </span>

        <div>
          <Link to="/contact-us/#ourOffices" title={settings.address}>
            {settings.address}
          </Link>
        </div>

        <span>Company Nr: {settings.company_number}. &nbsp;</span>
        <span>
          ICO Registration Nr:{' '}
          <a href={`https://ico.org.uk/ESDWebPages/Entry/${settings.ico_number}`}>{settings.ico_number}</a>. &nbsp;
        </span>
        <span>VAT Registration Nr: {settings.vat_number}&nbsp;</span>
      </div>
    </div>
  );
};
