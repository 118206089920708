import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';

import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import Tooltip from 'components/common/tooltip/Tooltip';
import { useWindowWidth } from 'hooks/window-width';
import { WIDTH_SM } from 'utils/pixel-widths';
import { Props } from './InfoIcon.interface';

import { infoIcon } from './InfoIcon.scss';

const InfoIcon = ({ slug, tooltip, iconClassName, containerClass }: Props): JSX.Element => {
  const isMobile = WIDTH_SM >= useWindowWidth();

  if (!isMobile) {
    return (
      <Tooltip
        id={slug}
        trigger={<FontAwesomeIcon icon={faInfoCircle} className={infoIcon} />}
        tooltip={tooltip}
        iconClassName={iconClassName}
        containerClass={containerClass}
      />
    );
  }

  return (
    <Modal triggerClass={containerClass} trigger={<FontAwesomeIcon icon={faInfoCircle} className={iconClassName} />}>
      <div>
        <Markdown source={tooltip} container />
      </div>
    </Modal>
  );
};

export default InfoIcon;
