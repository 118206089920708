// extracted by mini-css-extract-plugin
export var banner = "ReviewCentreOverview--banner--5ItlN";
export var banner__ratings__glider = "ReviewCentreOverview--banner__ratings__glider--xTk0h";
export var banner__title = "ReviewCentreOverview--banner__title--skvUx";
export var carousel = "ReviewCentreOverview--carousel--qe4d1";
export var dots = "ReviewCentreOverview--dots--V4wtb";
export var pane = "ReviewCentreOverview--pane--iMk3Z";
export var pane__maxScore = "ReviewCentreOverview--pane__maxScore--HQ34S";
export var pane__provider = "ReviewCentreOverview--pane__provider--rNEpF";
export var pane__score = "ReviewCentreOverview--pane__score--Epf9g";
export var pane__totalReviews = "ReviewCentreOverview--pane__totalReviews--gxKSx";
export var tkMyriadProSemiCondensed = "ReviewCentreOverview--tk-myriad-pro-semi-condensed--ScCFD";
export var tray = "ReviewCentreOverview--tray--w781F";