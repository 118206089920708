import React, { useEffect, useState } from 'react';

import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Button from 'components/common/button/Button';
import packagesHook from 'hooks/packages/packages.hook';
import pageTitleHook from 'hooks/page-title/page-title.hook';
import { useCookies } from 'react-cookie';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { url } from 'utils/url';

import { Props } from './BuyNow.interface';
import {
  buyNow__price___small,
  buyNow__price___smallPrefix,
  buyNow__price___smallSuffix,
  buyNow__price___discounted,
  buyNow__pricesWrapper,
  buyNow__prices,
  buyNow__price,
  buyNow__buttons,
  buyNow__chFee,
} from './BuyNow.scss';

export default ({ slug, isPackage = false, prices = false }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, pageTitleHook());
  const [cookies] = useCookies();
  const [companyName, setCompanyName] = useState<string>('');

  const styles = {
    small: buyNow__price___small,
    prefix: buyNow__price___smallPrefix,
    suffix: buyNow__price___smallSuffix,
    strike: buyNow__price___discounted,
  };

  useEffect(() => {
    setCompanyName(cookies['company-name']);
  }, [cookies['company-name']]);

  const BuyButton = (): JSX.Element => {
    if (isPackage) {
      /* tslint:disable-next-line */
      const { node } = isPackage ? packagesHook().filter(({ node }) => node.slug === slug)[0] : null;

      return (
        <>
          {companyName !== '' && typeof companyName !== 'undefined' ? (
            <>
              <Button
                to={url.account(`${node.checkout_path}?name=${encodeURIComponent(companyName)}&gle=namecheck`)}
                aria-label={`Buy our ${node.name} package now.`}
                role="primary"
              >
                Buy Now
              </Button>
            </>
          ) : (
            <Button to={node.namecheck_path} aria-label={`Buy our ${node.name} package now.`} role="primary">
              Buy Now
            </Button>
          )}
        </>
      );
    }
    return (
      <Button to={`${node.buy_link}`} role="primary">
        Buy Now
      </Button>
    );
  };

  return (
    <>
      {prices && (
        <div className={buyNow__pricesWrapper}>
          <div className={buyNow__prices}>
            <div className={`${buyNow__price} ${node.discounted_price ? styles.strike : ''}`}>
              {node.price && `£${node.price.value}`}
            </div>
            {node.discounted_price && (
              <div className={buyNow__price}>{node.discounted_price && `£${node.discounted_price.value}`}</div>
            )}
          </div>
          {isPackage && <span className={buyNow__chFee}>+ £50 Companies House Fee</span>}
        </div>
      )}
      {node.buttons.length > 0 && (
        <ButtonGroup className={buyNow__buttons}>
          {node.buttons.indexOf('Buy Now') !== -1 && <BuyButton />}
          {node.buttons.indexOf('View Packages') !== -1 && (
            <Button role="secondary" to="/compare-packages/">
              View Packages
            </Button>
          )}
        </ButtonGroup>
      )}
    </>
  );
};
