import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';
import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import Carousel from 'components/directus/carousel/Carousel';
import { component__link } from 'components/directus/customer-reviews/CustomerReviews.scss';
import RatingStars from 'components/directus/rating-stars/RatingStars';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import recentReviewsHook from 'hooks/recent-reviews/recent-reviews.hook';
import customerReviewsHook from 'hooks/customer-reviews/customer-reviews.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { checkFloat } from 'utils/check-float';
import { Props } from './RecentReviews.interface';
import {
  reviewPane,
  reviewPane__review,
  reviewPane__stars,
  reviewPane__details,
  reviewPane__customer,
  reviewPane__company,
  reviewStats__container,
  reviewStats__item,
  reviewStats__top,
  reviewStats__bottom,
  reviewStats__link,
} from './RecentReviews.scss';

const ReviewPane = ({ customer_name, company_name, review, review_date, review_platform, logo }: Props) => (
  <div className={reviewPane}>
    <div className={reviewPane__review}>
      <p>
        <Markdown source={review} /> <span>{review_date}</span>
      </p>
    </div>
    <div className={reviewPane__stars}>
      <RatingStars score={5} />
    </div>
    <div className={reviewPane__details}>
      <div>
        <span className={reviewPane__customer}>{customer_name}</span>
        <span className={reviewPane__company}>{company_name}</span>
      </div>
      <img
        src={logo.data.full_url}
        alt={`${review_platform} logo.`}
        width={Math.ceil(logo.width * 0.6)}
        height={Math.ceil(logo.height * 0.6)}
        style={{ width: Math.ceil(logo.width * 0.6), height: Math.ceil(logo.height * 0.6) }}
        loading="lazy"
      />
    </div>
  </div>
);

export default () => {
  const { node: section } = getFieldBySlug('recent-reviews', sectionTitleHook());
  const allDirectusRecentReview = recentReviewsHook();
  const stats = customerReviewsHook().filter(({ node }) => node.show);

  return (
    <>
      <SectionTitle title={section.heading} subtitle={section.subheading} align="center" />

      <div className={reviewStats__container}>
        {stats
          .sort((prev, next) => prev.node.sort - next.node.sort)
          .map(({ node }) => (
            <div className={reviewStats__item} key={`review-stats-${slugify(node.provider)}`}>
              <div className={reviewStats__top}>
                <div className="pane__image_container">
                  <Img fixed={node.logo.localFile.childImageSharp.fixed} alt={`${node.provider} logo`} />
                </div>
                <RatingStars score={node.score} starColor={node.colour} />
              </div>
              <div className={reviewStats__bottom}>
                <div className="pane__reviews">{node.total_reviews.toLocaleString()} reviews</div>
                <div className="pane__ratings">
                  Rated {checkFloat(node.score) ? `${node.score}.0` : node.score} out of{' '}
                  {checkFloat(node.max_score) ? `${node.max_score}.0` : node.max_score}
                </div>
              </div>
              <Link
                to={node.link}
                className={reviewStats__link}
                target="_blank"
                rel="noreferrer"
                aria-label={`Click here to see reviews on ${node.provider}`}
              >
                &nbsp;
              </Link>
            </div>
          ))}
      </div>

      {/* Reviews Carousel */}
      <div style={{ marginBottom: '2em' }}>
        <Carousel height={420} width={730} maxVisibleSlides={2} hasArrows gap dots lazy>
          {allDirectusRecentReview.map(({ node }) => (
            <div key={`review-${slugify(node.customer_name)}-${node.directusId}`}>
              <ReviewPane {...node} />
            </div>
          ))}
        </Carousel>
      </div>

      <ButtonGroup block>
        <Button role="secondary" size="large" to="/customer-reviews/" className={component__link}>
          Our Customer Reviews
        </Button>
      </ButtonGroup>
    </>
  );
};
