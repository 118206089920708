import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import Markdown from 'components/common/markdown/Markdown';
import Carousel from 'components/directus/carousel/Carousel';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import WhyChooseUsHook from 'hooks/why-choose-us/why-choose-us.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { CarouselProps } from './WhyChooseUs.interface';

import {
  carouselPane,
  carouselPane__left,
  carouselPane__right,
  whyChooseUs as whyChooseUsClass,
} from './WhyChooseUs.scss';

const CarouselPane = ({ title, body, image }: CarouselProps) => (
  <div className={carouselPane}>
    <div className={carouselPane__left}>
      <h3>{title}</h3>
      <Markdown source={body} container />
    </div>
    <BackgroundImage className={carouselPane__right} fluid={image.localFile.childImageSharp.fluid} fadeIn />
  </div>
);

export default (): JSX.Element => {
  const whyChooseUs = WhyChooseUsHook();
  const { node: section } = getFieldBySlug('why-choose-us', sectionTitleHook());

  return (
    <>
      <SectionTitle title={section.heading} align="center" />
      <Carousel height={420} width={730} maxVisibleSlides={1} hasArrows dots lazy>
        {whyChooseUs.map(({ node }) => (
          <div className={whyChooseUsClass} key={node.title}>
            <CarouselPane title={node.title} body={node.body} image={node.image} />
          </div>
        ))}
      </Carousel>
    </>
  );
};
