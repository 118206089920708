// extracted by mini-css-extract-plugin
export var company = "CompanyStructure--company--QNbFX";
export var company__block = "CompanyStructure--company__block--rU6ne";
export var company__body = "CompanyStructure--company__body--4y0Od";
export var company__button = "CompanyStructure--company__button--0eUyM";
export var company__content = "CompanyStructure--company__content--lomme";
export var company__image = "CompanyStructure--company__image--5NtUL";
export var company__imageWrapper = "CompanyStructure--company__imageWrapper--Oos+M";
export var company__title = "CompanyStructure--company__title--ZW1qT";
export var highlight = "CompanyStructure--highlight--5nt+0";
export var tkMyriadProSemiCondensed = "CompanyStructure--tk-myriad-pro-semi-condensed--AE+K6";