import React from 'react';
import slugify from 'react-slugify';

import Carousel, { Pane } from 'components/directus/carousel/Carousel';
import customerReviews from 'hooks/customer-reviews/customer-reviews.hook';
import { checkFloat } from 'utils/check-float';
import ReviewCentreOverviewFaq from './review-centre-overview-faq/ReviewCentreOverviewFaq';

import {
  banner,
  banner__title,
  carousel as carouselClass,
  tray,
  pane,
  pane__provider,
  pane__score,
  pane__maxScore,
  pane__totalReviews,
} from './ReviewCentreOverview.scss';

export default (): JSX.Element => {
  const stats = customerReviews().filter(({ node }) => node.show);

  return (
    <>
      <div className={banner}>
        <h3 className={banner__title}>How we are rated</h3>
        <div className={carouselClass}>
          <Carousel
            width={300}
            height={250}
            maxVisibleSlides={5}
            hasArrows
            trayClass={tray}
            changeOnResize
            dots={false}
          >
            {stats
              .sort((prev, next) => prev.node.sort - next.node.sort)
              .map(({ node }) => (
                <Pane key={`panel-${slugify(node.provider)}`}>
                  <span className="glider-slide">
                    <a href={node.link} rel="noreferrer" target="_blank" className={pane}>
                      <span className={pane__provider}>{node.provider}</span>
                      <span className={pane__score}>{checkFloat(node.score) ? `${node.score}.0` : node.score}</span>
                      <span className={pane__maxScore}>
                        out of {checkFloat(node.max_score) ? `${node.max_score}.0` : node.max_score}
                      </span>
                      <span className={pane__totalReviews}>
                        {parseInt(node.total_reviews as string, 10).toLocaleString()} reviews
                      </span>
                    </a>
                  </span>
                </Pane>
              ))}
          </Carousel>
        </div>
      </div>

      <div>
        <ReviewCentreOverviewFaq />
      </div>
    </>
  );
};
