// extracted by mini-css-extract-plugin
export var reviewPane = "RecentReviews--reviewPane--nItym";
export var reviewPane__company = "RecentReviews--reviewPane__company--Ngxqh";
export var reviewPane__customer = "RecentReviews--reviewPane__customer--n+FvN";
export var reviewPane__details = "RecentReviews--reviewPane__details--Xqngr";
export var reviewPane__review = "RecentReviews--reviewPane__review--Cz37H";
export var reviewPane__stars = "RecentReviews--reviewPane__stars--JgSns";
export var reviewStats__bottom = "RecentReviews--reviewStats__bottom--meLLl";
export var reviewStats__container = "RecentReviews--reviewStats__container--6lLa6";
export var reviewStats__item = "RecentReviews--reviewStats__item--DfuNh";
export var reviewStats__link = "RecentReviews--reviewStats__link--DO+C9";
export var reviewStats__top = "RecentReviews--reviewStats__top--e6qe+";
export var tkMyriadProSemiCondensed = "RecentReviews--tk-myriad-pro-semi-condensed--YN9HM";