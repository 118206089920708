// extracted by mini-css-extract-plugin
export var packageGrid = "PackageGrid--packageGrid--EXiS4";
export var packageGrid__buyButton = "PackageGrid--packageGrid__buyButton--J+SzZ";
export var packageGrid__description = "PackageGrid--packageGrid__description--q6cg7";
export var packageGrid__detailsButton = "PackageGrid--packageGrid__detailsButton--CoGdL";
export var packageGrid__header = "PackageGrid--packageGrid__header--+9XQf";
export var packageGrid__includedItems = "PackageGrid--packageGrid__includedItems--AmIgg";
export var packageGrid__includedItems__list = "PackageGrid--packageGrid__includedItems__list--fLVw7";
export var packageGrid__includedItems__listItem = "PackageGrid--packageGrid__includedItems__listItem--eHNNt";
export var packageGrid__infoIcon = "PackageGrid--packageGrid__infoIcon--P+jIl";
export var packageGrid__infoIcon__trigger = "PackageGrid--packageGrid__infoIcon__trigger--E7GzC";
export var packageGrid__name = "PackageGrid--packageGrid__name--semm2";
export var packageGrid__package = "PackageGrid--packageGrid__package--dgyh+";
export var packageGrid__price = "PackageGrid--packageGrid__price--1IQJu";
export var packageGrid__price___chFee = "PackageGrid--packageGrid__price___chFee--5mkVe";
export var packageGrid__price___discounted = "PackageGrid--packageGrid__price___discounted---QiBo";
export var packageGrid__prices = "PackageGrid--packageGrid__prices--nao05";
export var packageGrid__specialOffer = "PackageGrid--packageGrid__specialOffer---GJzw";
export var tkMyriadProSemiCondensed = "PackageGrid--tk-myriad-pro-semi-condensed--+Zh3o";