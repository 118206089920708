import React from 'react';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Carousel from 'components/directus/carousel/Carousel';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import companyStructureHook from 'hooks/company-structure/company-structure.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Link } from 'components/common/link/Link';

import {
  company,
  company__block,
  company__image,
  company__imageWrapper,
  company__content,
  company__title,
  company__body,
  company__button,
} from './CompanyStructure.scss';

export default (): JSX.Element => {
  const structures = companyStructureHook();
  const { node } = getFieldBySlug('company-structure', sectionTitleHook());

  return (
    <>
      <SectionTitle title={node.heading} align="center" />
      <div className={company}>
        <Carousel maxVisibleSlides={3} mdSlidesToShow={1} changeOnResize lazy gap>
          {structures.map(
            ({ node }): JSX.Element => (
              <div key={node.directusId} className={company__block}>
                <Link to={node.link} className={company__imageWrapper}>
                  <Img className={company__image} fluid={node.image.localFile.childImageSharp.fluid} fadeIn />
                </Link>
                <div className={company__content}>
                  <Link to={node.link}>
                    <h3 className={company__title}>{node.title}</h3>
                  </Link>
                  <p className={company__body}>{node.body}</p>
                  <ButtonGroup block>
                    <Button role="secondary" to={node.link} className={company__button} block>
                      {node.button_text}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            )
          )}
        </Carousel>
      </div>
    </>
  );
};
