// extracted by mini-css-extract-plugin
export var buyNow__buttons = "BuyNow--buyNow__buttons--xVNu8";
export var buyNow__chFee = "BuyNow--buyNow__chFee--dRdrh";
export var buyNow__price = "BuyNow--buyNow__price--7dHOY";
export var buyNow__price___discounted = "BuyNow--buyNow__price___discounted--Ph+SX";
export var buyNow__price___small = "BuyNow--buyNow__price___small--SkkR-";
export var buyNow__price___smallPrefix = "BuyNow--buyNow__price___smallPrefix--CmWr9";
export var buyNow__price___smallSuffix = "BuyNow--buyNow__price___smallSuffix--r3K3H";
export var buyNow__prices = "BuyNow--buyNow__prices--sgSrT";
export var buyNow__pricesWrapper = "BuyNow--buyNow__pricesWrapper--Z6xMV";
export var tkMyriadProSemiCondensed = "BuyNow--tk-myriad-pro-semi-condensed--xmDwn";