import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPackageGrid, PackageGrid } from './package-grid.interface';

export default (): PackageGrid[] => {
  const { allDirectusPackageGrid } = useStaticQuery<AllDirectusPackageGrid>(graphql`
    query {
      allDirectusPackageGrid {
        edges {
          node {
            directusId
            package {
              directusId
              slug
              name
              description
              price {
                value
              }
              discounted_price {
                value
              }
              namecheck_path
              checkout_path
            }
            items {
              directusId
              sort
              slug
              name
              tooltip
            }
            ribbon_text
          }
        }
      }
    }
  `);

  return allDirectusPackageGrid.edges;
};
