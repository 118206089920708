import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import { Link } from 'components/common/link/Link';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import InfoIcon from 'components/common/info-icon/InfoIcon';

import packageGridHook from 'hooks/package-grid/package-grid.hook';

import { url } from 'utils/url';

import {
  packageGrid,
  packageGrid__package,
  packageGrid__header,
  packageGrid__name,
  packageGrid__prices,
  packageGrid__price,
  packageGrid__price___chFee,
  packageGrid__includedItems,
  packageGrid__includedItems__list,
  packageGrid__includedItems__listItem,
  packageGrid__infoIcon,
  packageGrid__infoIcon__trigger,
  packageGrid__buyButton,
  packageGrid__detailsButton,
  packageGrid__specialOffer,
} from './PackageGrid.scss';

export default (): JSX.Element => {
  const packages = packageGridHook();
  const [cookies] = useCookies();
  const [companyName, setCompanyName] = useState<string>('');

  useEffect(() => {
    setCompanyName(cookies['company-name']);
  }, [cookies['company-name']]);

  return (
    <div className={packageGrid}>
      {packages.map(({ node }) => {
        const { slug, name, price, discounted_price, checkout_path, namecheck_path } = node.package;
        return (
          <div key={`package-grid-package-${slug}}`} className={packageGrid__package}>
            <div className={packageGrid__header}>
              <h3 className={packageGrid__name}>
                <Link to={`/package/${slug}/`}>{name}</Link>
              </h3>
              <div className={packageGrid__prices}>
                <>
                  <span className={packageGrid__price}>
                    <PriceWithoutVat price={discounted_price ? discounted_price.value : price.value} />
                  </span>
                  <span className={packageGrid__price___chFee}>+ £50 Companies House Fee</span>
                </>
              </div>
            </div>
            <div className={packageGrid__includedItems}>
              <h5>Highlights</h5>
              <ul className={packageGrid__includedItems__list}>
                {node.items.map(({ slug, name, tooltip }) => (
                  <li key={slug} className={packageGrid__includedItems__listItem}>
                    <InfoIcon
                      slug={slug}
                      tooltip={tooltip}
                      containerClass={packageGrid__infoIcon}
                      iconClassName={packageGrid__infoIcon__trigger}
                    />
                    <span>{name}</span>
                  </li>
                ))}
              </ul>
            </div>
            <ButtonGroup block column>
              {companyName !== '' && typeof companyName !== 'undefined' ? (
                <>
                  <Button
                    to={url.account(`${checkout_path}?name=${encodeURIComponent(companyName)}&gle=namecheck`)}
                    className={packageGrid__buyButton}
                    aria-label={`Buy our ${name} package now.`}
                    role="primary"
                  >
                    Buy Now
                  </Button>
                </>
              ) : (
                <Button
                  to={namecheck_path}
                  className={packageGrid__buyButton}
                  aria-label={`Buy our ${name} package now.`}
                  role="primary"
                >
                  Buy Now
                </Button>
              )}
              <Button role="secondary" to={`/package/${slug}/`} className={packageGrid__detailsButton} block>
                Read More
              </Button>
            </ButtonGroup>
            {node.ribbon_text && (
              <div className={packageGrid__specialOffer}>
                <span>{node.ribbon_text}</span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
