import React, { useEffect, useState, useRef, useCallback } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import OutsideClickHandler from 'react-outside-click-handler';

import { Props, ModalProps } from './Modal.interface';

import {
  modal,
  modal___fade,
  modal__container,
  modal__container___video,
  modal__container___videoTransparent,
  modal__header,
  modal__close,
  modal__body,
  modal__container___videoWhite,
} from './Modal.scss';

const Modal = ({ children, portalRoot, el }: ModalProps) => {
  useEffect(() => {
    portalRoot.appendChild(el);

    return () => {
      if (portalRoot) {
        portalRoot.removeChild(el);
      }
    };
  });

  return createPortal(children, el);
};

export default ({
  children,
  containerClass,
  fade = false,
  trigger,
  lazy = false,
  isVideo = false,
  span = false,
  triggerClass,
  transparent = false,
  white = false,
}: Props): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef(null);

  const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;
  const el = typeof document !== 'undefined' ? document.createElement('div') : null;

  if (!el) {
    return (
      <div role="presentation" className={triggerClass}>
        {trigger}
      </div>
    );
  }

  const handleEscape = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isVisible) document.addEventListener('keydown', handleEscape, false);
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isVisible]);

  const SkeletonTrigger = () => {
    if (span) {
      return (
        <span onClick={() => setIsVisible(true)} ref={triggerRef} role="presentation">
          {trigger}
        </span>
      );
    }
    return (
      <div onClick={() => setIsVisible(true)} ref={triggerRef} role="presentation" className={triggerClass}>
        {trigger}
      </div>
    );
  };
  return (
    <>
      {lazy ? (
        <LazyHydrate whenVisible>
          <SkeletonTrigger />
        </LazyHydrate>
      ) : (
        <SkeletonTrigger />
      )}
      {isVisible && (
        <Modal portalRoot={portalRoot} el={el}>
          <div className={`${modal} ${fade ? modal___fade : ''}`}>
            <OutsideClickHandler onOutsideClick={() => setIsVisible(false)}>
              <div
                className={`${modal__container} ${isVideo ? modal__container___video : ''} ${
                  white ? modal__container___videoWhite : ''
                } ${transparent ? modal__container___videoTransparent : ''} ${containerClass}`}
              >
                <div className={modal__header}>
                  <FontAwesomeIcon onClick={() => setIsVisible(false)} icon={faTimes} className={modal__close} />
                </div>
                <div className={modal__body}>{children}</div>
              </div>
            </OutsideClickHandler>
          </div>
        </Modal>
      )}
    </>
  );
};
