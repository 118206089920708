/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback } from 'react';
import Img from 'gatsby-image';

import Modal from 'components/common/modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import Markdown from 'components/common/markdown/Markdown';
import { getFieldBySlug } from 'utils/directus';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import caseStudyHook from 'hooks/case-study/case-study.hook';
import {
  mosaic,
  mosaic__items,
  mosaic__items__icon,
  mosaic__items__modal,
  mosaic__items__excerpt,
  mosaic__items__excerpt__subheading,
} from './CaseStudyMosaic.scss';
import SectionTitle from '../section-title/SectionTitle';

export default (): JSX.Element => {
  const removedCaseStudies = [
    'chalk-and-chilli',
    'the-strap-tailor',
    'westudyaway',
    'we-buy-supercars',
    'literal-humans',
  ];
  const videoRef = useCallback((node: HTMLVideoElement) => {
    if (node) {
      node.volume = 0.5;
    }
  }, []);
  const { node } = getFieldBySlug('case-study-mosaic', sectionTitleHook());
  const casestudies = caseStudyHook()
    .filter(({ node }) => !removedCaseStudies.includes(node.slug))
    .sort((prev, next) => prev.node.sort - next.node.sort);

  return (
    <>
      <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />
      <div className={mosaic}>
        {casestudies.map(({ node }) => (
          <Modal
            key={node.slug}
            isVideo
            triggerClass={mosaic__items}
            white
            trigger={
              <>
                <FontAwesomeIcon icon={faPlay} className={mosaic__items__icon} />
                <Img fluid={node.image.localFile.childImageSharp.fluid} />
                <div className={mosaic__items__excerpt}>
                  <p>{node.company_name}</p>
                  <div className={mosaic__items__excerpt__subheading}>
                    <Markdown source={node.excerpt} />
                  </div>
                </div>
              </>
            }
          >
            <div className={mosaic__items__modal}>
              <video controls crossOrigin="anonymous" autoPlay ref={videoRef}>
                <source src={node.video_link} type="video/mp4" />
              </video>
            </div>
          </Modal>
        ))}
      </div>
    </>
  );
};
