import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import { Link } from 'components/common/link/Link';
import Carousel, { Pane } from 'components/directus/carousel/Carousel';
import RatingStars from 'components/directus/rating-stars/RatingStars';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import customerReviewsHook from 'hooks/customer-reviews/customer-reviews.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { checkFloat } from 'utils/check-float';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './CustomerReviews.interface';

import {
  pane,
  pane__image_container,
  pane__image,
  pane__stars,
  pane__ratings,
  pane__reviews,
  component__carousel,
  component__link,
} from './CustomerReviews.scss';

export default (): JSX.Element => {
  const { node: section } = getFieldBySlug('customer-reviews', sectionTitleHook());
  const stats = customerReviewsHook().filter(({ node }) => node.show);

  const GliderPane = ({ image, reviews, score, maxScore, starColor, url }: Props): JSX.Element => (
    <span className="glider-slide">
      <Link to={url} className={pane} newTab>
        <div className={pane__image_container}>
          <Img
            fixed={image.localFile.childImageSharp.fixed}
            className={pane__image}
            style={{ width: image.width, height: image.height, maxWidth: '200px', maxHeight: '50px' }}
            loading="lazy"
          />
        </div>
        <RatingStars center score={score} starColor={starColor} className={pane__stars} />
        <p className={pane__ratings}>
          Rated <strong>{checkFloat(score) ? `${score}.0` : score}</strong> out of{' '}
          {checkFloat(maxScore) ? `${maxScore}.0` : maxScore}
        </p>
        <p className={pane__reviews}>{reviews}&nbsp;reviews</p>
      </Link>
    </span>
  );

  return (
    <>
      <SectionTitle title={section.heading} subtitle={section.subheading} align="center" />
      <div className={component__carousel}>
        <Carousel width={300} height={225} maxVisibleSlides={stats.length} changeOnResize lazy>
          {stats
            .sort((prev, next) => prev.node.sort - next.node.sort)
            .map(({ node }) => (
              <Pane key={`review-stats-${slugify(node.provider)}`}>
                <GliderPane
                  title={node.provider}
                  starColor={node.colour}
                  image={node.logo}
                  score={node.score}
                  maxScore={node.max_score}
                  reviews={node.total_reviews.toLocaleString()}
                  url={node.link}
                  imageHeight={45.35}
                />
              </Pane>
            ))}
        </Carousel>
      </div>
      <ButtonGroup block>
        <Button role="secondary" size="large" to="/customer-reviews/" className={component__link}>
          Our Customer Reviews
        </Button>
      </ButtonGroup>
    </>
  );
};
